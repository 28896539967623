<template>
    <div id="echarts6"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts6',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts6"));
            this.option = {
                title: {
                    text: '当月在途状态分析',
                    left: '20%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                visualMap: {
                    show: false,
                    min: 80,
                    max: 600,
                    inRange: {
                        colorLightness: [0, 1]
                    }
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: [
                            {value: 335, name: '今日预约在途'},
                            {value: 310, name: '预约超时在途'},
                            {value: 274, name: '明日及之后预约在途'}
                        ].sort(function (a, b) { return a.value - b.value; }),
                        roseType: 'radius',
                        label: {
                            color: '#fff'
                        },
                        labelLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        },
                        itemStyle: {
                            color: '#c23531',
                            shadowBlur: 200,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },
                        animationType: 'scale',
                        animationEasing: 'elasticOut',
                        animationDelay: function () {
                            return Math.random() * 200;
                        }
                    }
                ]
            };
            this.echarts.setOption(this.option)
        }
    }
}
</script>

<style scoped>
#echarts6 {
    width: 100%;
    height: 100%;
}
</style>