<template>
    <div id="echarts9"></div>
</template>

<script>
    import echarts from '../assets/js/echarts'

    export default {
        name: 'echarts9',
        mounted() {
            this.echartsInit();
        },
        methods: {
            echartsInit() {
                this.echarts = echarts.init(document.getElementById("echarts9"));
                this.option = {
                    title: {
                        text: '装机质检通过率',
                        left: '15%',
                        textAlign: 'center',
                        textStyle: {
                            color: '#fff',
                            lineHeight: 30
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['质检识别率', '质检合格率', '质检通过率'],
                        textStyle: {color: '#fff'},
                        top: 30
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['吴兴', '南浔', '德清', '长兴', '安吉', '湖州']
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '质检识别率',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [99.49, 99.64, 99.60, 96.52, 99.57, 99.02]
                        },
                        {
                            name: '质检合格率',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [97.10, 94.87, 92.80, 94.95, 96.51, 95.60]
                        },
                        {
                            name: '质检通过率',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [97.82, 96.30, 94.84, 95.72, 97.42, 96.63]
                        }

                    ]
                };
                this.echarts.setOption(this.option)
            }
        }
    }
</script>

<style scoped>
    #echarts9 {
        width: 100%;
        height: 100%;
    }
</style>
