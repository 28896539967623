<template>
    <div id="echarts4"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts4',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts4"));
            this.option = {
                title: {
                    text: '本月装机回访满意度',
                    left: '16%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                /* toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        dataView: {show: true, readOnly: false},
                        restore: {show: true},
                        saveAsImage: {show: true}
                    }
                }, */
                series: [
                    {
                        name: '面积模式',
                        type: 'pie',
                        radius: [20, '75%'],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 5
                        },
                        label: {
                            color: '#fff',
                            fontWeight: 'bold'
                        },
                        data: [
                            {value: 28, name: '吴兴'},
                            {value: 11, name: '南浔'},
                            {value: 26, name: '德清'},
                            {value: 18, name: '长兴'},
                            {value: 11, name: '安吉'},
                        ].sort(function (a,b) {
                            return a.value - b.value;
                        })
                    }
                ]
            };
            this.echarts.setOption(this.option)
        }
    }
}
</script>

<style scoped>
#echarts4 {
    width: 100%;
    height: 100%;
}
</style>
