<template>
    <div id="echarts1"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts1',
    mounted() {
        this.echartsInit()
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts1"))
            this.option = {
                title: {
                    text: '当月累计业务量',
                    left: '16%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['累计', '当日约当日装'],
                    textStyle: {
                        color: '#fff'
                    },
                    bottom: 15
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // data: (function (){
                        //     var now = new Date();
                        //     var res = [];
                        //     var len = 10;
                        //     while (len--) {
                        //         res.unshift(now.toLocaleTimeString().replace(/^\D*/,''));
                        //         now = new Date(now + 1000*60*60);
                        //     }
                        //     return res;
                        // })()
                        data: ['吴兴', '南浔', '德清', '长兴', '安吉', '湖州']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        max: 30,
                        min: 0,
                        boundaryGap: [0.2, 0.2],
                    },
                    {
                        type: 'value',
                        scale: true,
                        max: 1200,
                        min: 0,
                        boundaryGap: [0.2, 0.2]
                    }
                ],
                series: [
                    {
                        name: '累计',
                        type: 'bar',
                        xAxisIndex: 0,
                        yAxisIndex: 1,
                        data: (function (){
                            var res = [];
                            var len = 10;
                            while (len--) {
                                res.push(Math.round(Math.random() * 1000));
                            }
                            return res;
                        })()
                    },
                    {
                        name: '当日约当日装',
                        type: 'line',
                        data: (function (){
                            var res = [];
                            var len = 0;
                            while (len < 10) {
                                res.push(parseInt(Math.random()*10 + 5));
                                len++;
                            }
                            return res;
                        })()
                    }
                ]
            };
            this.echarts.setOption(this.option);
        }
    }
}
</script>

<style scoped>
#echarts1 {
    width: 100%;
    height: 100%;
}
</style>
