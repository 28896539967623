<template>
    <div id="echarts7"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts7',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            let _this = this;
            this.echarts = echarts.init(document.getElementById("echarts7"));
            this.option = {
                title: {
                    text: '产能综合分析',
                    left: '25%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                series: [{
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    radius: '90%',
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#464646'
                        }
                    },
                    axisLine: {

                        lineStyle: {
                            width: 40
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: [{
                        value: 20,
                        name: '在途',
                        title: {
                            fontSize: 12,
                            color: "#fff",
                            offsetCenter: ['0%', '-50%']
                        },
                        detail: {
                            fontSize: 12,
                            offsetCenter: ['0%', '-30%']
                        }
                    },
                    {
                        value: 40,
                        name: '竣工量',
                        title: {
                            color: "#fff",
                            fontSize: 12,
                            offsetCenter: ['0%', '-10%']
                        },
                        detail: {
                            fontSize: 12,
                            offsetCenter: ['0%', '10%']
                        }
                    },
                    {
                        value: 60,
                        name: '最大产值',
                        title: {
                            color: "#fff",
                            fontSize: 12,
                            offsetCenter: ['0%', '30%']
                        },
                        detail: {
                            fontSize: 12,
                            offsetCenter: ['0%', '50%']
                        }
                    }
                    ],
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 'auto',
                        height: 14,
                        fontSize: 12,
                        color: 'auto',
                        borderColor: 'auto',
                        borderRadius: 20,
                        borderWidth: 1,
                        formatter: '{value}%'
                    }
                }]
            };
            setInterval(function () {
                console.log(_this.option)
                _this.option.series[0].pointer.show = false;
                _this.option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
                _this.option.series[0].data[1].value = (Math.random() * 100).toFixed(2) - 0;
                _this.option.series[0].data[2].value = (Math.random() * 100).toFixed(2) - 0;
                _this.echarts.setOption(_this.option, true);
            }, 2000);
            this.echarts.setOption(this.option)
        }
    }
}
</script>

<style scoped>
#echarts7 {
    width: 100%;
    height: 100%;
}
</style>