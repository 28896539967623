<template>
    <div id="echarts3">

    </div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts3',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts3"));
            this.option = {
                legend: {
                    data: ['装机综合满意度','维护综合满意度','装机及时率','投诉处理及时率'],
                    textStyle: {
                        color: '#fff',
                        fontSize: 10
                    },
                    bottom: 15
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {type: 'category' , data: ['2020-03-01', '2020-03-02', '2020-03-03', '2020-03-04', '2020-03-05', '2020-03-06']},
                yAxis: {gridIndex: 0},
                series: [
                    {name: '装机综合满意度',type: 'line', smooth: true,data: [56.5, 82.1, 88.7, 70.1, 53.4, 85.1]},
                    {name: '维护综合满意度',type: 'line', smooth: true,data: [51.1, 51.4, 55.1, 53.3, 73.8, 68.7]},
                    {name: '装机及时率',type: 'line', smooth: true,data: [40.1, 62.2, 69.5, 36.4, 45.2, 32.5]},
                    {name: '投诉处理及时率',type: 'line', smooth: true,data: [25.2, 37.1, 41.2, 18, 33.9, 49.1]},
                ]
            };
            this.echarts.setOption(this.option)
        }
    }
}
</script>

<style scoped>
#echarts3 {
    width: 100%;
    height: 100%;
}
</style>
