<template>
    <dv-full-screen-container>
        <div class="header">
            <span>家宽装维服务质量动态</span>
        </div>
        <div class="container">
            <div class="col-1">
                <div class="row-1">
                    <dv-border-box-8>
                        <echarts1></echarts1>
                    </dv-border-box-8>
                </div>
                <div class="row-2">
                    <dv-border-box-8>
                        <echarts2></echarts2>
                    </dv-border-box-8>
                </div>
                <div class="row-3">
                    <dv-border-box-8>
                        <echarts3></echarts3>
                    </dv-border-box-8>
                </div>
            </div>
            <div class="col-2">
                <!-- <div class="row-1">
                    <dv-border-box-5>
                    <div class="broadBand-head">
                        <p>在线人数</p>
                        <p><span>567</span><span>人</span></p>
                    </div>
                    </dv-border-box-5>
                    <dv-border-box-5 :reverse="true">
                        <div class="broadBand-head">
                        <p>宽带维护量</p>
                        <p><span>8717</span><span>户</span></p>
                    </div>
                    </dv-border-box-5>
                </div> -->
                <div class="row-2">
                    <Map></Map>
                </div>
                <div class="row-3">
                    <div class="row-3-1">
                        <dv-border-box-8>
                            <echarts7></echarts7>
                        </dv-border-box-8>
                    </div>
                    <div class="row-3-2">
                        <dv-border-box-8>
                            <echarts8></echarts8>
                        </dv-border-box-8>
                    </div>
                    <div class="row-3-3">
                        <dv-border-box-8>
                            <echarts6></echarts6>

                        </dv-border-box-8>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="row-1">
                    <dv-border-box-8>
                        <echarts4></echarts4>
                    </dv-border-box-8>
                </div>
                <div class="row-2">
                    <dv-border-box-8>
                        <echarts5></echarts5>
                    </dv-border-box-8>
                </div>
                <div class="row-3">
                    <dv-border-box-8>
                        <echarts9></echarts9>
                    </dv-border-box-8>
                </div>
            </div>
        </div>
    </dv-full-screen-container>
</template>

<script>
import Vue from 'vue'
import echarts1 from '../components/echarts1'
import echarts2 from '../components/echarts2'
import echarts3 from '../components/echarts3'
import echarts4 from '../components/echarts4'
import echarts5 from '../components/echarts5'
import echarts6 from '../components/echarts6'
import echarts7 from '../components/echarts7'
import echarts8 from '../components/echarts8'
import echarts9 from '../components/echarts9'
import {fullScreenContainer , borderBox8 , borderBox5} from '@jiaminghi/data-view'
Vue.use(fullScreenContainer)
Vue.use(borderBox8)
Vue.use(borderBox5)
import Map from '../components/map'
export default {
    components: {
        Map,
        echarts1,
        echarts2,
        echarts3,
        echarts4,
        echarts5,
        echarts6,
        echarts7,
        echarts8,
        echarts9,
    },
    methods: {

    }
}
</script>

<style scoped>
.header {
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    line-height: 65px;
    background: url("../assets/header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.header span {
    background-image: linear-gradient(78deg, #149CDC 0%, #2DEAF8 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
}
.container {
    width: 100%;
    padding: 20px;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
}
.col-1 {
    flex: 2.5;
    display: flex;
    flex-direction: column;
}
.col-1 .row-1 {
    flex: 3.5;
    position: relative;
}
.col-1 .row-2 {
    flex: 3.5;
    padding: 10px 0;
    position: relative;
}
.col-1 .row-3 {
    flex: 3;
    position: relative;
}
.col-1 .row-1::after , .col-1 .row-2::after , .col-1 .row-3::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(26, 89, 251, 0.2) 0%, #1B65FC 100%);
    box-shadow: 0px 0px 50px 0px #02EAFF;
    opacity: 0.2;
    z-index: -1000;
}
.col-2 {
    flex: 5;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}
.col-2 .row-1 {
    flex: 1.5;
    display: flex;
    position: relative;
}
/* .col-2 .row-1 .broadBand-head {
    font-size: 18px;
    color: #88c1ff;
}
.col-2 .row-1 .broadBand-head p:nth-of-type(2) {
    font-size: 54px;
    color: #00eeda;
}
.col-2 .row-1 .broadBand-head p:nth-of-type(2) span:nth-of-type(2) {
    font-size: 16px;
    color: #88C1FF
} */
.col-2 .row-2 {
    flex: 5.5;
    padding-bottom: 10px;
    position: relative;
}
.col-2 .row-3 {
    flex: 3;
    display: flex;
}
.col-2 .row-3 .row-3-1 {
    flex: 3;
    position: relative;
}
.col-2 .row-3 .row-3-2 {
    flex: 4;
    padding: 0 10px;
    position: relative;
}
.col-2 .row-3 .row-3-3 {
    flex: 3;
    position: relative;
}
.col-2 .row-3 .row-3-1::after , .col-2 .row-3 .row-3-2::after , .col-2 .row-3 .row-3-3::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(26, 89, 251, 0.2) 0%, #1B65FC 100%);
    box-shadow: 0px 0px 50px 0px #02EAFF;
    opacity: 0.2;
    z-index: -1000;
}
.col-3 {
    flex: 2.5;
    display: flex;
    flex-direction: column;
}
.col-3 .row-1 {
    flex: 3;
    position: relative;
}
.col-3 .row-1::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(26, 89, 251, 0.2) 0%, #1B65FC 100%);
    box-shadow: 0px 0px 50px 0px #02EAFF;
    opacity: 0.2;
    z-index: -1000;
}
.col-3 .row-2 {
    flex: 4;
    padding: 10px 0;
    position: relative;
}
.col-3 .row-2::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(26, 89, 251, 0.2) 0%, #1B65FC 100%);
    box-shadow: 0px 0px 50px 0px #02EAFF;
    opacity: 0.2;
    z-index: -1000;
}
.col-3 .row-3 {
    flex: 3;
    position: relative;
}
.col-3 .row-3::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(180deg, rgba(26, 89, 251, 0.2) 0%, #1B65FC 100%);
    box-shadow: 0px 0px 50px 0px #02EAFF;
    opacity: 0.2;
    z-index: -1000;
}
/* data-view样式 */
#dv-full-screen-container {
    background-color: #000;
    display: flex;
    flex-direction: column;
}
.col-2 .row-1 .dv-border-box-5 {
    flex: 0 0 260px;
}
.col-2 .row-1 .dv-border-box-5 >>>.border-box-content {
    display: flex;
    padding-left: 20px;
    align-items: center;
}
</style>
