<template>
    <div id="echarts2"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts2',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts2"));
            this.option = {
                title: {
                    text: '当月出勤及产量均值',
                    left: '20%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                legend: {
                    data: ['日均出勤人数', '日均值产量'],
                    bottom: 15,
                    textStyle: {
                        color: '#fff'
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['吴兴', '南浔', '德清', '长兴', '安吉', '湖州']
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '日均出勤人数',
                        type: 'bar',
                        stack: '人数',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: '日均值产量',
                        type: 'bar',
                        stack: '人数',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    }
                ]
            };
            this.echarts.setOption(this.option);
        }
    }
}
</script>

<style scoped>
#echarts2 {
    width: 100%;
    height: 100%;
}
</style>
