<template>
    <div id="map">
        <div class="head">
            <div class="broadBand-head">
                <p>在线人数</p>
                <p><span>567</span><span>人</span></p>
            </div>
            <div class="broadBand-head">
                <p>宽带维护量</p>
                <p><span>8717</span><span>户</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import AMap from 'AMap'
import defImg from '../assets/default.png'
export default {
    name: 'Map',
    data() {
        return {
            map: new Object(),
            colors: {}
        }
    },
    mounted() {
        this.mapInitialize();
    },
    methods: {
        mapInitialize() {
            console.log(AMap)
            this.map = new AMap.Map("map", {
                resizeEnable: true,
                mapStyle: "amap://styles/darkblue",
                center: [120.203143, 30.865162],
                zoom: 11
            });
            this.getMarker();
            this.divideTheArea();
        },
        /* 获取点标记 */
        getMarker() {
            let inglats = [
                [120.015659,30.785549],
                [120.276015,30.868944],
                [120.203143, 30.865162],
                [119.966348,30.804621]
            ]
            //创建信息窗
            let infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(-1, -30)});
            //创建图标
            let icon = new AMap.Icon({
                size: new AMap.Size(50 , 40),
                image: defImg,
                /* imageOffset: new AMap.Pixel(0, -60), */
                imageSize: new AMap.Size(25 , 34),
            });
            console.log(icon)
            for(let i = 0; i < inglats.length; i++) {
                let marker = new AMap.Marker({
                    icon: icon,
                    position: inglats[i],
                    /* offset: new AMap.Pixel(0 , 0) */
                    offset: new AMap.Pixel(-14, -30)
                })
                marker.content = `
                <div>
                <div>
                <p class='input-item' style="font-size: 14px;color:#4a4a4a">班组 :吴兴区八里店1组</p>
                <p class='input-item' style="font-size: 14px;color:#4a4a4a">姓名 : 张三   电话 : 13588888888</p>
                </div></div>
                `;
                this.map.add(marker)
                marker.on('click', (e)=> {
                    infoWindow.setContent(e.target.content);
                    infoWindow.open(this.map, e.target.getPosition());
                })
            }
        },
        divideTheArea() {
            // let _this = this;
            this.disProvince = new AMap.DistrictLayer.Province({
                zIndex:12,
                adcode:['330502'],
                depth:2,
                styles:{
                    'fill':function(){
                        // var adcode = properties.adcode;
                        // return _this.getColorByAdcode(adcode);
                        return '#1d3275'
                    },
                    'province-stroke':'cornflowerblue',
                    'city-stroke': 'white',//中国地级市边界
                    'county-stroke': 'rgba(255,255,255,0.5)'//中国区县边界
                }
            });
            this.disProvince.setMap(this.map);
        },
        getColorByAdcode(adcode) {
            if (!this.colors[adcode]) {
                var gb = Math.random() * 155 + 50;
                this.colors[adcode] = 'rgb(' + gb + ',' + gb + ',255)';
            }
            return this.colors[adcode];
        }
    }
}
</script>

<style scoped>
#map {
    width: 100%;
    height: 100%;
    position: relative;
}
#map .head {
    width: auto;
    height: auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    font-size: 18px;
    color: #88c1ff;
}
.head .broadBand-head {
    font-size: 20px;
    color: #88c1ff;
    padding: 10px 0;
}
.head .broadBand-head p:nth-of-type(2) {
    font-size: 54px;
    color: #00eeda;
}
.head .broadBand-head p:nth-of-type(2) span:nth-of-type(2) {
    font-size: 16px;
    color: #88C1FF
}
</style>
