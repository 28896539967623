<template>
    <div id="echarts5"></div>
</template>

<script>
import echarts from '../assets/js/echarts'
export default {
    name: 'echarts5',
    mounted() {
        this.echartsInit();
    },
    methods: {
        echartsInit() {
            this.echarts = echarts.init(document.getElementById("echarts5"));
            this.option = {
                title: {
                    text: '维修服务优良率',
                    left: '15%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        lineHeight: 30
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['智能网关弱光率', '有线接入率', '投诉及时率', '优良率']
                },
                yAxis: {
                    type: 'value',
                    max: 100,
                    min: 0
                },
                series: [{
                    data: [0.70, 97.21, 99.43, 95.98],
                    type: 'line',
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'rgba(14, 206, 241, 0.2)' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(14, 206, 241, 0)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    }
                }]
            };
            this.echarts.setOption(this.option)
        }
    }
}
</script>

<style scoped>
#echarts5 {
    width: 100%;
    height: 100%;
}
</style>
